<template>
  <el-config-provider :locale="language">
    <router-view/>
  </el-config-provider>
  <div class="loading" v-if="loading">
    <i class="iconfont icon-loading"></i>
  </div>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
export default {
  components:{
    ElConfigProvider
  },
  computed:{
    loading(){
      return this.$store.getters['basic/get_loading']
    },
    language(){
      let lan = this.$store.getters['basic/get_language'];
      if (lan === 'zh'){
        return zhCn
      }else {
        return en
      }
    }
  },
  created() {
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', async () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
}
</script>

<style lang="scss">
@import "assets/iconfont.css";
@import "assets/main.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading{
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e54d42;
  font-size: 30px;
  @keyframes animal {
    0%{
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  i{
    color: #e54d42;
    font-size: 40px;
    animation: animal 1.4s infinite linear;
    transform-origin: center center;
  }
}

</style>
