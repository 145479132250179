
export default {
    message:{
        home:'首页',
        confirm:'确认',
        cancel:'取消',
        save:'保存',
        welcomeBack:'欢迎回来',
        dialogTitle:'弹窗标题',
        changePassword:'修改密码',
        logout:'退出系统',
        notArrived:'待到仓',
        inHouse:'待装柜',
        transit:'运输中',
        arrived:'待提货',
        search:'查询',
        pendingOrder:'待开单',
        trans:'待转运',
        transfer:'转运中',
        success:'已完成',
        createdTime:'创建时间',
        total:'共',
        operation:'操作',
        delete:'删除',
        edit:'编辑',
        prevText:'上一页',
        nextText:'下一页'
    },
    aside:{
        service:'我的服务',
        order:'订单管理',
        address:'地址管理',
        profile:'个人中心'
    },
    login:{
        phoneLabel:'请输入手机',
        passwordLabel: '请输入密码',
        registerTitle: '注册新账号',
        nameLabel:'请输入客户编号',
        register:'注册',
        cancel:'取消',
        login:'登录',
        notAccount:'没有账号？注册',
        phone:'手机',
        name:'呢称',
        password:'密码'
    },
    home:{
        copyAll:'复制全部',
        copy:'复制',
        consignee:'收件人',
        address:'地址',
        mobile:'电话',
        addTrackingNumber:'添加快递单号',
        addTrackingLabel:'从您的供应商或网上商店添加跟踪号码',
        placeAnOrder:'下单',
        placeAnOrderLabel:'向我们订购海外空运/海运订单',
    },
    order:{
        addOrder:'添加订单',
        courierNo:'快递单号',
        orderStatus:'订单状态',
        startHouse:'交货仓库',
        country:'国家',
        transType:'运输方式',
        endHouse:'目的仓库',
        consignee:'收件人',
        address:'收件地址',
        mobile:'收件电话',
        orderNo:'订单号',
        clearance:'报关',
        cabinetNo:'柜号',
        customNo:'客户编号',
        goods:'品名',
        sumWeight:'合计重量',
        sumVolume:'合计体积',
        sumNumber:'合计件数',
        priceVolume:'计费体积',
        sendTime:'发货时间',
        ship:'海运',
        plane:'空运',
        name:'姓名'
    },
    detail:{
        title:'订单详情',
        titleLabelOne:'"蓝色"当前节点',
        titleLabelTwo:'"黑色"下一节点',
        basic:'基本信息',
        settle:'结算信息',
        orderNo:'订单号',
        sendTime:'发货时间',
        startHouse:'建单仓库',
        endHouse:'目的仓库',
        goods:'商品',
        sumWeight:'总重量',
        sumVolume:'总体积',
        sumNumber:'总件数',
        priceVolume:'计费体积',
        remark:'备注',
        customName:'客户名称',
        customNo:'客户编号',
        name:'收件人',
        mobile:'收件电话',
        dimension:'尺寸',
        weight:'重量',
        ctn:'件数',
        volume:'体积',
        package:'快递单号',
        container:'柜号',
        etd:'到港时间',
        eta:'开船时间',
        clearance:'报关',
        clearanceYes:'是',
        clearanceNot:'否',
        logisticsStatus:'订单轨迹'
    }
}