
const state = {
    user:null,
    loading:false,
    action:null,
    pageList:[],
    page:'',
    language:'en',
}
const mutations = {
    set_user(state,data){
        if (!data){
            Object.keys(state).forEach(item => {
                if (item === 'loading'){
                    state[item] = false
                }else if (item === 'pageList'){
                    state[item] = []
                }else if (item === 'language'){
                    state[item] = 'en'
                }else {
                    state[item] = null
                }
            })
        }else {
            state.user = data;
        }

    },
    set_loading(state,data){
        state.loading = data;
    },
    set_action(state,data){
        state.action = data;
    },
    set_pageList(state,data){
        state.pageList = data;
    },
    set_page(state,data){
        state.page = data;
    },
    set_language(state,data){
        state.language = data;
    },
}
const actions = {
    up_user({commit},data){
        commit('set_user',data);
    },
    up_loading({commit},data){
        commit('set_loading',data);
    },
    up_action({commit},data){
        commit('set_action',data);
    },
    up_pageList({commit},data){
       commit('set_pageList',data);
    },
    up_page({commit},data){
        commit('set_page',data);
    },
    up_language({commit},data){
        commit('set_language',data)
    },
}
const getters = {
    get_user(state){
        return state.user;
    },
    get_loading(state){
        return state.loading;
    },
    get_action(state){
        return state.action ?? [];
    },
    get_pageList(state){
        return state.pageList;
    },
    get_page(state){
        return state.page;
    },
    get_language(state){
        return state.language
    },
    get_languageList(state){
        if (state.language === 'en'){
            return [
                {text: 'English', value: 'english'},
                {text: 'Chinese', value: 'chinese'},
            ]
        }else {
            return [
                {text: '英文', value: 'english'},
                {text: '中文', value: 'chinese'},
            ]
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};