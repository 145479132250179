<template>
    <div class="cu-main">
      <div class="nav-box solid-bottom">
        <div class="left-box">
          <i class="iconfont icon-jiantou_yemian_xiangzuo"></i>
        </div>
        <div class="nav-item-group">
          <el-scrollbar>
            <div class="scrollbar-flex-content">
              <p class="scrollbar-demo-item"
                 :class="page === '/home' ?'active':''"
                 @click.stop="clickSelect('/home')">{{$t('message.home')}}</p>
              <p v-for="(item,index) in pageList" :key="index"
                 class="scrollbar-demo-item"
                 @click.stop="clickSelect(item.path)"
                 :class="item.path === page ?'active':''">
                {{ $t(item.title) }}
                <i class="iconfont icon-guanbi1" v-if="item.close && item.path === page"
                   @click.stop="clickClose(item)"></i>
              </p>
            </div>
          </el-scrollbar>
        </div>
        <div class="right-box">
          <i class="iconfont icon-jiantou_yemian_xiangyou"></i>
        </div>
      </div>
      <div class="main-box">
        <el-scrollbar height="100%">
          <div class="padding-10">
            <router-view v-slot="{ Component }">
              <keep-alive>
                <component
                    :is="Component"
                    v-if="$route.meta.keepAlive"
                    :key="$route.name"
                />
              </keep-alive>
              <component
                  :is="Component"
                  v-if="!$route.meta.keepAlive"
                  :key="$route.name"
              />
            </router-view>
          </div>
        </el-scrollbar>
      </div>
    </div>
</template>

<script>
import {closePage} from "@/utils/menu";

export default {
  name: "cu-main",
  computed:{
    pageList(){
      return this.$store.getters['basic/get_pageList'];
    },
    page(){
      return this.$store.getters['basic/get_page'];
    }
  },
  methods:{
    clickClose(row){
      closePage(row);
    },
    clickSelect(path){
      this.$router.push({path:path});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/layout";
.cu-main{
  width: calc(100% - #{$aside-width});
  height: calc(100% - #{$header-height});
  position: fixed;
  z-index: 1000;
  top: $header-height;
  left: $aside-width;
  background-color: #f8f8f8;
  .nav-box{
    height: $nav-box-height;
    display: flex;
    .left-box,.right-box{
      width: $nav-box-height;
      height: 100%;
      line-height: $nav-box-height;
      text-align: center;
      cursor: pointer;
      background-color: #eeeeee;
    }

    .left-box:hover,.right-box:hover{
      color: #3980c0;
      font-size: 20px;
    }

    .nav-item-group{
      width: calc(100% - #{$nav-box-height} - #{$nav-box-height});
      height: 100%;
    }
  }
  .main-box{
    height: calc(100% - #{$nav-box-height});
  }
}

.scrollbar-flex-content {
  display: flex;
  padding-top: 5px;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  margin-right: 5px;
  text-align: center;
  border-radius: 2px;
  background-color: #dcdfe6;
  font-size: 13px;
  cursor: pointer;
  color: #000000;
  .iconfont{
    font-size: 16px;
    margin-left: 5px;
  }
}

.scrollbar-demo-item.active{
  background-color: #42b983;
  color: #f8f8f8;

  .iconfont:hover{
    color: #f37b1d;
  }
}
</style>