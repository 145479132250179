import {createRouter, createWebHistory} from 'vue-router'
import Layout from "@/views/layout/index";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from "@/store/index";
import {updatePage} from "@/utils/menu";
import {listToLevel} from "@/utils/common";

NProgress.configure({showSpinner: false})

const defaultPage = ['/login', '/home', '/404', '/notFound',];

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登陆',
            hidden: true,
        },
        component: () => import('@/views/system/Login.vue')
    },
    {
        path: '/',
        name: 'layout',
        redirect: '/home',
        component: Layout,
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    title: '首页',
                    hidden: true,
                },
                component: () => import('@/views/HomeView.vue')
            },
            {
                path: '/notFound',
                name: 'notFound',
                meta: {
                    title: 'notFound',
                    hidden: false
                },
                component: () => import('@/views/system/NotFound')
            },
            {
                path:'/order/list',
                name:'order-list',
                meta:{
                    title: '订单管理',
                    hidden: false,
                },
                component: () => import('@/views/service/order-list')
            },
            {
                path:'/address',
                name:'address',
                meta:{
                    title:'地址管理',
                    hidden: false,
                },
                component: () => import('@/views/service/address-settings')
            },
            {
                path:'/profile',
                name: 'profile',
                meta: {
                    title:'个人中心',
                    hidden: false,
                },
                component: () => import('@/views/service/profile-settings')
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        // 是手机访问，跳转到A域名
        window.location.href = 'https://app.dsc56.com';
    }else {
        NProgress.start();

        //在页面加载时读取sessionStorage里的状态信息
        if (window.sessionStorage.getItem("store")) {
            store.replaceState(
                Object.assign({}, JSON.parse(sessionStorage.getItem("store")))
            );
            window.sessionStorage.removeItem("store");
        }

        //检测是否登陆
        let user = store.getters['basic/get_user'];
        if (!user && to.path !== '/login') {
            next({
                path: '/login'
            });
        } else {

            if (to.path === '/login') {
                store.dispatch('basic/up_user', null).then(r => r);
            }

            //动态路由注入
            let action = store.getters['basic/get_action'];
            //检测权限
            let page = action.find(item => '/' + item['path'] === to.path);
            let isDefault = defaultPage.includes(to.path);
            if (to.matched.length === 0 || (!page && !isDefault)) {
                next({
                    path: '/notFound'
                })
            } else {
                next();
            }
            updatePage(to).then(r => r);
        }
        document.title = to.meta.title ? 'DscCargo:' + to.meta.title : 'DscCargo';
    }


})

router.afterEach(() => {
    NProgress.done();
})


const getMapRouters = (list) => {
    let storeRouters = listToLevel({list: list, keys: 'menu_id'});
    let data = [];
    storeRouters.forEach(row => {
        row.children.forEach(item => {
            data.push({
                path: item.path,
                name: item.title,
                meta: {
                    title: item.title,
                    hidden: false,
                },
                component: () => import(`../views/${item.component}`),
            })
        })
    })
    return [{
        path: '/',
        name: 'layout2',
        component: Layout,
        children: data
    }];
}

export default router
