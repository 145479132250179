<template>
  <div>
    <el-dialog v-model="options.show"
               append-to-body
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :width="options.width ?? '50%'"
               :title="$t(title)"
               @close="handleClick('close')"
               :top="options.top ?? '60px'"
               :lock-scroll="true"
    >
      <template #header v-if="options.header ?? false">
        <slot name="header"></slot>
      </template>
      <slot></slot>
      <template v-if="options.footer ?? true" #footer>
        <div class="dialog-footer flex justify-end">
          <el-button size="small" @click="handleClick('close')">{{ $t(cancelText) }}</el-button>
          <el-button size="small" type="primary" @click="handleClick('confirm')">
            {{ $t(confirmText) }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cu-dialog",
  props: {
    options: {
      type:Object,
      default: () => {
        return {
          show: false,
          cancelText: '取消',
          confirmText: '确定',
          errorMessage:''
        }
      }
    },
    title:{
      type:String,
      default:'message.dialogTitle'
    },
    cancelText:{
      type:String,
      default:'message.cancel'
    },
    confirmText:{
      type:String,
      default:'message.confirm'
    }
  },
  methods: {
    handleClick(type) {
      this.$emit(type)
    }
  }
}
</script>

<style scoped>

</style>