
import http from "@/utils/http";

export default {
    login: data => {
        return http({url:'/api/webapp/v1/user/login',data}).then(r => r);
    },
    address: () => {
        return http({url:'/api/webapp/v1/user/getAddress',}).then(r => r);
    },
    update: data => {
        return http({url:'/api/webapp/v1/user/updateInfo',data,tip:true}).then(r => r);
    },
    updatePassword: data => {
        return http({url:'/api/webapp/v1/user/updatePassword',data,tip:true}).then(r => r);
    },
    register: data => {
        return http({url:'/api/webapp/v1/user/register',data,tip:true}).then(r => r);
    }
}