export default {
    message:{
        home:'Home',
        confirm:'confirm',
        cancel:'cancel',
        save:'save',
        welcomeBack:'Welcome Back',
        dialogTitle:'Dialog Title',
        changePassword:'ChangePassword',
        logout:'Logout',
        notArrived:'Not Arrived',
        inHouse:'In Warehouse',
        transit:'In Transit',
        arrived:'Arrived',
        search:'Search',
        pendingOrder:'Pending Waybill',
        trans:'Pending transfer',
        transfer:'Transfer',
        success:'Success',
        createdTime:'Created Time',
        total:'Total',
        operation:'Operation',
        delete:'Delete',
        edit:'Edit',
        prevText:'Prev Page',
        nextText:'Next Page'
    },
    aside:{
        service:'Service',
        order:'My Order',
        address:'My Address',
        profile:'Personal'
    },
    login:{
        phoneLabel:'Phone Number',
        passwordLabel: 'Password',
        registerTitle: 'Register With DSC',
        nameLabel:'Your Customer Number',
        register:'Registration',
        cancel:'Cancel',
        login:'Login',
        notAccount:'Not registered yet? create',
        phone:'Phone',
        name:'Name',
        password:'Password'
    },
    home:{
        copyAll:'Copy Full Shipping Address',
        copy:'copy',
        consignee:'Consignee',
        address:'Address',
        mobile:'Mobile',
        addTrackingNumber:'Add Tracking Number',
        addTrackingLabel:'Add tracking number from your suppliers or online stores',
        placeAnOrder:'Place An Order',
        placeAnOrderLabel:'Place an oversea air/sea shipping order with us',
    },
    order:{
        addOrder:'Add Order',
        courierNo:'Courier No',
        orderStatus:'Order Status',
        startHouse:'Warehouse',
        country:'Country',
        transType:'Type of',
        endHouse:'Arrived Warehouse',
        consignee:'Consignee',
        address:'Address',
        mobile:'Mobile',
        orderNo:'Order No',
        clearance:'Clearance',
        cabinetNo:'Cabinet No',
        customNo:'Custom No',
        goods:'Goods',
        sumWeight:'Total Weight',
        sumVolume:'Total Volume',
        sumNumber:'Total Number',
        priceVolume:'Price Volume',
        sendTime:'Send Time',
        ship:'SEA',
        plane:'AIR',
        name:'Nick Name'
    },
    detail:{
        title:'Order Details',
        titleLabelOne:'"Blue" current node',
        titleLabelTwo:'"Black" next node',
        basic:'Basic',
        settle:'Settle',
        orderNo:'Tracking No',
        sendTime:'Send Time',
        startHouse:'Warehouse',
        endHouse:'Arrived Warehouse',
        goods:'Goods',
        sumWeight:'Total Weight',
        sumVolume:'Total Volume',
        sumNumber:'Total Number',
        priceVolume:'Price Volume',
        remark:'Remark',
        customName:'Custom Name',
        customNo:'Custom Number',
        name:'Consignee',
        mobile:'Mobile',
        dimension:'Dimension',
        weight:'Weight',
        ctn:'Ctn',
        volume:'Volume',
        package:'Courier No',
        container:'Container',
        etd:'ETD',
        eta:'ETA',
        clearance:'Clearance',
        clearanceYes:'Yes',
        clearanceNot:'Not',
        logisticsStatus:'Logistics Status'


    }
}