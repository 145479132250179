<template>
<div>
  <div class="cu-header flex align-center justify-between">
    <div class="flex align-center">
      <div class="company-box cursor flex align-center">
        <div class="logo">
          <img src="/logo.png" alt="">
        </div>
      </div>
    </div>
    <div class="flex align-center margin-right-20">
      <div class="flex margin-right-25">
        <div class="language-box left" :class="language === 'zh' ? 'active':''" @click.stop="changeLanguage('zh')">中文</div>
        <div class="language-box right" :class="language === 'en' ? 'active':''" @click.stop="changeLanguage('en')">English</div>
      </div>
      <div class="margin-right-25 cursor">
        <el-badge :value="0" :is-dot="true" v-if="0">
          <i class="iconfont icon-lingdang-xianxing text-white text-20 text-hover-yellow"></i>
        </el-badge>
        <i class="iconfont icon-lingdang-xianxing text-white text-20 text-hover-yellow" v-if="!0"></i>
      </div>
<!--      <div class="margin-right-25 cursor" @click.stop="toFullScreen()">-->
<!--        <i class="iconfont icon-quanping text-white text-18 text-hover-yellow" v-if="!fullscreen"></i>-->
<!--        <i class="iconfont icon-xiaoping02 text-white text-18 text-hover-yellow" v-if="fullscreen"></i>-->
<!--      </div>-->

      <div class="flex align-center cursor text-white user-box" @click.stop="showUserBtn = !showUserBtn">
        <div class="avatar cursor border-hover-yellow">
          <img v-if="user && user['avatar'] !== ''" :src="user['avatar']" alt="">
          <img v-else src="/avatar.jpeg" alt="">
        </div>
        <div v-if="user" class="margin-left-5 text-hover-yellow flex align-center">
          <span>{{user['nick_name']}}</span>
          <div class="margin-left-5">
            <span>[</span>
            <span class="text-orange">{{user['custom_no']}}</span>
            <span>]</span>
          </div>
        </div>
        <div class="user-btn text-black-0" v-if="showUserBtn">
          <div class="user-btn-item solid-bottom flex align-center" @click.stop="handleChangePassword()">
            <i class="iconfont icon-gerentouxiang margin-right-5"></i>
            <span>{{$t('message.changePassword')}}</span>
          </div>
          <div class="user-btn-item flex align-center" @click.stop="logout()">
            <i class="iconfont icon-dengchu margin-right-5"></i>
            <span>{{$t('message.logout')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <cu-dialog :options="passOption" @close="passOption.show = false" @confirm="passConfirm()">
    <el-form :model="passwordForm">
      <el-form-item label="旧密码">
        <el-input v-model="passwordForm.old_password"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input v-model="passwordForm.new_password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input v-model="passwordForm.con_pwd"></el-input>
      </el-form-item>
    </el-form>
  </cu-dialog>
</div>
</template>

<script>
import CuDialog from "@/components/cu-dialog";
import {err_alert} from "@/utils/common";
import api from "@/api";
export default {
  name: "cu-header",
  components: {CuDialog},
  computed:{
    user(){
      return this.$store.getters['basic/get_user']
    },
    language(){
      return this.$store.getters['basic/get_language'];
    },
  },
  data() {
    return {
      fullscreen:false,
      showUserBtn:false,
      passOption:{
        show:false,
        title:'修改密码',
        width:'300px'
      },
      passwordForm:{
        old_password:'',
        new_password:'',
        con_pwd:''
      },
    }
  },
  methods:{
    changeLanguage(type){
      this.$i18n.locale = type;
      this.$store.dispatch('basic/up_language',type);
    },
    toFullScreen(){
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    logout(){
      this.$router.push({path:'/login'});
    },
    switchCompany(){
      this.$router.push({path:'/welcome'})
    },
    handleChangePassword(){
      this.passwordForm.old_password = '';
      this.passwordForm.new_password = '';
      this.passwordForm.con_pwd = '';
      this.passOption.show = true;
    },
    passConfirm(){
      if (this.passwordForm.old_password === '' || this.passwordForm.new_password === '' || this.passwordForm.con_pwd === ''){
        return false;
      }
      if (this.passwordForm.new_password !== this.passwordForm.con_pwd){
        err_alert('新密码两次输入不一致,请重输入');
        return false;
      }
      let self = this;
      api.user.updatePassword(this.passwordForm).then(res => {
        if (res.code === 0){
          self.passOption.show = false;
          setTimeout(()=>{
            self.logout();
          },600)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/layout";
.cu-header{
  height: $header-height;
  background-color: #102f31;
  position: fixed;
  z-index: 1100;
  width: 100%;
  .avatar{
    width: 31px;
    height: 31px;
    border-radius: 20px;

    padding: 3px;

    img{
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  .avatar{
    border: 1px solid rgba(255,255,255,.25);
  }

  .logo{
    width: 210px;
    img{
      width: 100%;
    }
  }
  .user-box{
    position: relative;
  }
  .user-btn{
    position: absolute;
    width: 160px;
    top: 46px;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 0 14px rgba(0,0,0,.2);
    .user-btn-item{
      height: 40px;
      line-height: 40px;
      padding-left: 15px;
    }
    .user-btn-item:hover{
      background-color: #fbbd08;
    }
  }
}

.language-box{
  padding: 2px 15px;
  background-color: #ffffff;
  color: #606266;
  cursor: pointer;
}
.language-box.left{
  border-radius: 5px 0 0 5px;
  border: 1px solid #dcdfe6;
}
.language-box.right{
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
}
.language-box.active{
  background-color: #409EFF;
  color: #ffffff;
  border-color: #409EFF;
}
</style>