import http from "@/utils/http";

export default {
    myNetOrderList: data => {
        return http({url:'/api/webapp/v1/order/getMyOrderPc',data}).then(r => r);
    },
    create: data => {
        return http({url:'/api/webapp/v1/order/create',data,tip:true}).then(r => r);
    },
    detail: data => {
        return http({url:'/api/webapp/v1/order/getOrderDetail',data}).then(r => r);
    }
}