
import http from "@/utils/http";

export default {
    startHouse: data => {
        return http({url:'/api/webapp/v1/station/getHouse',data}).then(r => r);
    },
    countryList: () => {
        return http({url:'/api/webapp/v1/station/getAllStationList',}).then(r => r);
    },
    endHouse: () => {
        return http({url:'/api/webapp/v1/station/getEndHouseList'}).then(r => r);
    }
}