import {createI18n} from "vue-i18n";

import en from './en-US'
import zh from './zh-CN'

export default new createI18n({
    locale:'en',
    messages:{
        'zh':zh,
        'en':en
    }
})