<template>
  <div class="cu-aside">
    <el-scrollbar height="100%">
      <div class="padding-5">
        <el-menu
            :default-active="page"
            class="el-menu-vertical-demo"
            @select="clickSelect"
        >
          <el-sub-menu :index="'/'+item.path" v-for="(item,index) in action" :key="index">
            <template #title>
              <i class="iconfont margin-right-5" :class="item.icon" style="font-size: 14px;color: black"></i>
              <span>{{$t(item.title)}}</span>
            </template>
            <el-menu-item v-for="(child,chx) in item.children" :key="chx"
                :index="'/'+child.path"
                          :class="chx !== (item.children.length - 1) ? 'border-bottom':''"
            >{{$t(child.title)}}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {listToLevel} from "@/utils/common";
import {openPage} from "@/utils/menu";

export default {
  name: "cu-aside",
  computed:{
    action(){
      return listToLevel({
        list:this.$store.getters['basic/get_action'],
        keys:'menu_id',
        pid:'0'
      });
    },
    actions(){
      return this.$store.getters['basic/get_action']
    },
    page(){
      return this.$store.getters['basic/get_page'];
    },
  },
  methods:{
    clickSelect(index,indexPath){
      let select = indexPath[indexPath.length - 1];
      let page = this.actions.find(item => '/'+item['path'] === select);
      openPage(page);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/layout";
.cu-aside{
  width: $aside-width;
  height: calc(100% - #{$header-height});
  background-color: #f4f5f6;
//border-right: solid 1px #dcdfe6;
  position: fixed;
  z-index: 1000;
  top: $header-height;
  left: 0;
}
</style>