
const RUN_SERVER = 'local';//开发环境变量

export const getBaseUrl = function (){
    let url = '';
    if (process.env.NODE_ENV === 'production'){
        url = 'https://api.dsc56.com';
    }else {
        switch (RUN_SERVER){
            case "local" : url = 'http://127.0.0.1:8787';break;
            case "stg": url = 'http://global56-api.kexikui.com';break;
            case "test":url = 'https://api.dsc56.com';break;
        }
    }
    return url;
}